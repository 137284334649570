import React, { useEffect, useRef, useState } from "react";
import { ThankYouInterface } from "../../Context";
import { GrTextAlignCenter } from "react-icons/gr";
import { GrTextAlignLeft } from "react-icons/gr";
import { GrTextAlignRight } from "react-icons/gr";

interface Props {
    idx : number,
    thankYouPage : ThankYouInterface,
    setThankYouPage : Function
}


const EndStyle = ({idx, thankYouPage, setThankYouPage}:Props) : React.ReactElement => {
    const screenPRef = useRef<HTMLInputElement>(null);
    const bgColorRef = useRef<HTMLInputElement>(null);
    const [align, setAlign] = useState<string>("left");

    const titleSRef = useRef<HTMLInputElement>(null);
    const titleCRef = useRef<HTMLInputElement>(null);

    const descSRef = useRef<HTMLInputElement>(null);
    const descCRef = useRef<HTMLInputElement>(null);

    const ctaSRef = useRef<HTMLInputElement>(null);
    const ctaPRef = useRef<HTMLInputElement>(null);
    const ctaBRef = useRef<HTMLInputElement>(null);
    const ctaFCRef = useRef<HTMLInputElement>(null);
    const ctaBCRef = useRef<HTMLInputElement>(null);

    const [endDraft, setEndDraft] = useState<ThankYouInterface>(thankYouPage);

    const updateChanges = (alignStr? : string) => {
        const newEnd : ThankYouInterface = {
            h1 : thankYouPage.h1,
            description : thankYouPage.description,
            ctaUrl : thankYouPage.ctaUrl,
            ctaText : thankYouPage.ctaText,
            textScreenStyle : {textAlign : (alignStr)?alignStr:align, padding : screenPRef.current?.value, backgroundColor : bgColorRef.current?.value},
            h1Style : {fontSize : titleSRef.current?.value, color : titleCRef.current?.value},
            pStyle : {fontSize : descSRef.current?.value, color : descCRef.current?.value},
            ctaStyle : {fontSize : ctaSRef.current?.value, 
                color : ctaFCRef.current?.value, backgroundColor : ctaBCRef.current?.value, 
                padding : ctaPRef.current?.value, borderRadius : ctaBRef.current?.value
            }
        }
        setThankYouPage(newEnd);
        setEndDraft(newEnd);
        sessionStorage.setItem(`end${idx}`, JSON.stringify(newEnd));
    }

    useEffect(()=>{
        const sessionEnd = sessionStorage.getItem(`end${idx}`);
        if(sessionEnd){
            setEndDraft(JSON.parse(sessionEnd));
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">End Screen Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/4">
                                <p className="mb-2">Text align</p>
                                <div className="flex flex-row gap-2">
                                    <p className={`${(align === "left")?"text-green":"text-black"}`} onClick={()=>{setAlign("left"); updateChanges("left")}}>
                                        <GrTextAlignLeft className="xl:size-8 lg:size-6"/>
                                    </p>
                                    <p className={`${(align === "center")?"text-green":"text-black"}`} onClick={()=>{setAlign("center"); updateChanges("center")}}>
                                        <GrTextAlignCenter className="xl::size-8 lg:size-6"/>
                                    </p>
                                    <p className={`${(align === "right")?"text-green":"text-black"}`} onClick={()=>{setAlign("right"); updateChanges("right")}}>
                                        <GrTextAlignRight className="xl:size-8 lg:size-6"/>
                                    </p>
                                </div>
                            </div>
                            <div className="w-2/4">
                                <p>Padding</p>
                                <input type="number" ref={screenPRef} defaultValue={endDraft.textScreenStyle.padding} placeholder="0px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/3">
                                <p>BG color</p>
                                <input type="color" ref={bgColorRef} value={endDraft.textScreenStyle.color}
                                className="w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Header</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="24px" ref={titleSRef} defaultValue={endDraft.h1Style.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={titleCRef} value={endDraft.h1Style.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Description</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="18px" ref={descSRef} defaultValue={endDraft.pStyle.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={descCRef} value={endDraft.pStyle.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">CTA Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/5">
                                <p>Font size</p>
                                <input type="number" placeholder="18px" ref={ctaSRef} defaultValue={endDraft.ctaStyle.fontSize}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Padding</p>
                                <input type="number" placeholder="5px" ref={ctaPRef} defaultValue={endDraft.ctaStyle.padding}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Border radius</p>
                                <input type="number" placeholder="10px" ref={ctaBRef} defaultValue={endDraft.ctaStyle.borderRadius}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Color</p>
                                <input type="color" ref={ctaFCRef} value={endDraft.ctaStyle.color} className="w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>BG color</p>
                                <input type="color" ref={ctaBCRef} value={endDraft.ctaStyle.backgroundColor} className="w-full"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    <div className={`text-${endDraft.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${endDraft.textScreenStyle.padding}px`, 
                        backgroundColor : `${endDraft.textScreenStyle.backgroundColor}`}}>
                        {(endDraft.h1)?
                        <p style={{fontSize : `${endDraft.h1Style.fontSize}px`, color : `${endDraft.h1Style.color}`}}>
                            {endDraft.h1}
                        </p>
                        :null}
                        {(endDraft.description)?
                        <p style={{fontSize : `${endDraft.pStyle.fontSize}px`, color : `${endDraft.pStyle.color}`}}>
                            {endDraft.description}
                        </p>
                        :null}
                        <button
                        style={{fontSize : `${endDraft.ctaStyle.fontSize}px`, padding :  `${endDraft.ctaStyle.padding}px`, borderRadius : `${endDraft.ctaStyle.borderRadius}px`,
                        color :  `${endDraft.ctaStyle.color}`, backgroundColor :  `${endDraft.ctaStyle.backgroundColor}`}}>
                            {(endDraft.ctaText)?endDraft.ctaText:"Continue"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EndStyle;