import React, { useRef, useState } from "react";
import { FormInterface, Step, TextScreenInterface, ThankYouInterface, VideoInterface } from "../../Context";

interface VideoPrevProps {
    video : VideoInterface,
    setCurrentStep : Function,
    currentStep : number 
}

const VideoPreview = ({video, setCurrentStep, currentStep} : VideoPrevProps) : React.ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const videoPrev : VideoInterface = (
        (video.textScreenStyle && video.h1Style && video.pStyle && video.ctaStyle)
        ? video
        : {
            url : video.url,
            poster : video.poster,
            showTimeLeft : video.showTimeLeft,
            timeLeftPersentage : video.timeLeftPersentage,
            timeLeftText : video.timeLeftText,
            CTAscreen : (video.CTAscreen) ? video.CTAscreen:(video.title && video.ctaText)?true:false,
            title : video.title,
            description : video.description,
            ctaText : video.ctaText,
            videoStyle : {width : "100", borderRadius : "10"},
            textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : ""},
            h1Style : {fontSize : "", color : ""},
            pStyle : {fontSize : "", color : ""},
            ctaStyle : {fontSize : "", color : "", padding :"", borderRadius : "", backgroundColor : ""}
        }
    ) 
    const [preview, setPreview] = useState({CTAscreen : false, timeLeft : false , timeText : 0});

    const DisplayTimeLeft = () => {
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(video.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 > video.timeLeftPersentage){
                        setPreview({...preview, timeLeft : true, timeText : Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0))});
                    }else{
                        setPreview({...preview, timeLeft : false, timeText : 0});
                    }
                }
            }
        }
    }

    return(
        <div>
            {
                (preview.CTAscreen)
                ?<div className={`text-${videoPrev.textScreenStyle.textAlign}`} style={{
                    padding : `${videoPrev.textScreenStyle.padding}px`, 
                    backgroundColor :`${videoPrev.textScreenStyle.backgroundColor}`
                }}>
                    {(videoPrev.title)?<p style={{fontSize : `${videoPrev.h1Style.fontSize}px`, color: `${videoPrev.h1Style.color}`}}>
                                            {videoPrev.title}
                                        </p>:null}
                    {(videoPrev.description)?<p style={{fontSize : `${videoPrev.pStyle.fontSize}px`, color: `${videoPrev.pStyle.color}`}}>
                                                {videoPrev.description}
                                                </p>:null}
                    {(videoPrev.ctaText)
                    ?<button style={{
                        fontSize : `${videoPrev.ctaStyle.fontSize}px`, 
                        color: `${videoPrev.ctaStyle.color}`, backgroundColor :`${videoPrev.ctaStyle.backgroundColor}`,
                        padding:`${videoPrev.ctaStyle.padding}px`, borderRadius : `${videoPrev.ctaStyle.borderRadius}px`
                    }}
                    onClick={()=>{setPreview({...preview, CTAscreen:false})}}>
                        {videoPrev.ctaText}
                    </button>
                    :null}
                </div>
                :<div>
                    <video ref={videoRef} src={videoPrev.url} poster={videoPrev.poster} onTimeUpdate={DisplayTimeLeft} controls onEnded={()=>{setCurrentStep(currentStep + 1)}}
                    style={{width : `${videoPrev.videoStyle.width}%`, borderRadius : `${videoPrev.videoStyle.borderRadius}px`}}>
                    </video>
                    {(preview.timeLeft && videoPrev.showTimeLeft)
                    ?<p className="text-right">{`${videoPrev.timeLeftText} : ${preview.timeText}s`}</p>
                    :null
                    }
                </div>
            }
        </div>
    );
}

interface FormPrevProps {
    form : FormInterface,
    setCurrentStep : Function,
    currentStep : number,
}

const FormPreview = ({form, setCurrentStep, currentStep} : FormPrevProps) : React.ReactElement => {
    const formPrev : FormInterface = (form.textScreenStyle && form.h1Style && form.pStyle && form.labelStyle && form.inputStyle && form.ctaStyle) 
    ? form 
    : {
        header : "",
        description : "",
        firstNameLabel : "",
        lastNameLabel : "",
        companyLabel : "",
        emailLabel : "",
        emailResults : "",
        ctaText : "",
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        labelStyle : {fontSize : "16", color : ""},
        inputStyle : {with : "50", padding : "0", fontSize :"16", borderRadius : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    return(
        <div className={`text-${formPrev.textScreenStyle.textAlign}`} style={{padding : `${formPrev.textScreenStyle.padding}px`, 
            backgroundColor : `${formPrev.textScreenStyle.backgroundColor}`}}>
            {(formPrev.header)?
            <p style={{fontSize : `${formPrev.h1Style.fontSize}px`, color : `${formPrev.h1Style.color}`}}>
                {formPrev.header}
            </p>
            :null}
            {(formPrev.description)?
            <p style={{fontSize : `${formPrev.pStyle.fontSize}px`, color : `${formPrev.pStyle.color}`}}>
                {formPrev.description}
            </p>
            :null}
            <form>
                {
                (form.inputFields && form.inputFields.length > 0)
                ?<>{form.inputFields.map((input : any, idx : number) => {
                    return<div key={idx}>
                            <label htmlFor={input.name}  style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                {input.label}
                            </label><br/>
                            <input readOnly type={input.type} name={input.type} className="border-[1px]"
                            style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                            fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/>
                        </div>
                })}</>
                :<>
                <label htmlFor="firstName" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                {(formPrev.firstNameLabel)?formPrev.firstNameLabel:"Fist name"}
                </label><br/>
                <input type="text" name="firstName" className="border-[1px]" 
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                <label htmlFor="lastName" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.lastNameLabel)?formPrev.lastNameLabel:"Last name"}
                </label><br/>
                <input type="text" name="lastName" className="border-[1px]"
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                <label htmlFor="email" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.emailLabel)?formPrev.emailLabel:"Email"}
                </label><br/>
                <input type="text" name="email" className="border-[1px]"
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                <label htmlFor="company" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.companyLabel)?formPrev.companyLabel:"Company"}
                    </label><br/>
                <input type="text" name="company" className="border-[1px]"
                style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                </>
                }
                <button className="mt-2" onClick={()=>{setCurrentStep(currentStep + 1)}}
                style={{fontSize : `${formPrev.ctaStyle.fontSize}px`, padding :  `${formPrev.ctaStyle.padding}px`, borderRadius : `${formPrev.ctaStyle.borderRadius}px`,
                color :  `${formPrev.ctaStyle.color}`, backgroundColor :  `${formPrev.ctaStyle.backgroundColor}`}}>
                    {(formPrev.ctaText)?formPrev.ctaText:"Submit form"}
                </button>
            </form>
        </div>
    );
};

interface TextPrevProps {
    textScreen : TextScreenInterface,
    setCurrentStep : Function,
    currentStep : number
}

const TextPreview = ({textScreen, setCurrentStep, currentStep} : TextPrevProps) : React.ReactElement => {
    const textPrev : TextScreenInterface = (textScreen.textScreenStyle && textScreen.h1Style && textScreen.pStyle && textScreen.ctaStyle)
    ?textScreen 
    :{
        h1 : textScreen.h1,
        description : textScreen.description,
        ctaText : textScreen.ctaText,
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }
    return(
        <div className={`text-${textPrev.textScreenStyle.textAlign}`} style={{padding : `${textPrev.textScreenStyle.padding}px`, 
                    backgroundColor : `${textPrev.textScreenStyle.backgroundColor}`}}>
            {(textPrev.h1)?
            <p style={{fontSize : `${textPrev.h1Style.fontSize}px`, color : `${textPrev.h1Style.color}`}}>
                {textPrev.h1}
            </p>
            :null}
            {(textPrev.description)?
            <p style={{fontSize : `${textPrev.pStyle.fontSize}px`, color : `${textPrev.pStyle.color}`}}>
                {textPrev.description}
            </p>
            :null}
            <button onClick={()=>{setCurrentStep(currentStep + 1);}}
            style={{fontSize : `${textPrev.ctaStyle.fontSize}px`, padding :  `${textPrev.ctaStyle.padding}px`, borderRadius : `${textPrev.ctaStyle.borderRadius}px`,
            color :  `${textPrev.ctaStyle.color}`, backgroundColor :  `${textPrev.ctaStyle.backgroundColor}`}}>
                {(textPrev.ctaText)?textPrev.ctaText:"Continue"}
            </button>
        </div>
    );
}

interface EndPrevProps {
    end : ThankYouInterface,
    setCurrentStep : Function,
    currentStep : number
}

const EndPreview = ({end, setCurrentStep, currentStep} : EndPrevProps) : React.ReactElement => {
    const endPrev : ThankYouInterface = (end.textScreenStyle && end.h1Style && end.pStyle && end.ctaStyle)
    ?end 
    :{
        h1 : end.h1,
        description : end.description,
        ctaText : end.ctaText,
        ctaUrl : end.ctaUrl,
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }
    return(
        <div className={`text-${endPrev.textScreenStyle.textAlign}`} style={{padding : `${endPrev.textScreenStyle.padding}px`, 
                    backgroundColor : `${endPrev.textScreenStyle.backgroundColor}`}}>
            {(endPrev.h1)?
            <p style={{fontSize : `${endPrev.h1Style.fontSize}px`, color : `${endPrev.h1Style.color}`}}>
                {endPrev.h1}
            </p>
            :null}
            {(endPrev.description)?
            <p style={{fontSize : `${endPrev.pStyle.fontSize}px`, color : `${endPrev.pStyle.color}`}}>
                {endPrev.description}
            </p>
            :null}
            <button onClick={()=>{setCurrentStep(currentStep + 1);}}
            style={{fontSize : `${endPrev.ctaStyle.fontSize}px`, padding :  `${endPrev.ctaStyle.padding}px`, borderRadius : `${endPrev.ctaStyle.borderRadius}px`,
            color :  `${endPrev.ctaStyle.color}`, backgroundColor :  `${endPrev.ctaStyle.backgroundColor}`}}>
                {(endPrev.ctaText)?endPrev.ctaText:"Continue"}
            </button>
        </div>
    );
}

interface Props {
    steps? : Step[]
}

const FunnelPreview = ({steps}:Props) : React.ReactElement => {
    const [currentStep, setCurrentStep] = useState<number>(0);

    return(
        <div className="m-5">
            {
                (steps && steps.length > 0)
                ?<div className="bg-brandLightGray p-5 rounded-xl shadow-md">
                    {steps.map((step : Step, idx : number) => {
                        if(step.action === "video" && idx === currentStep){
                            return <VideoPreview video={step.video!} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
                        }
                        if(step.action === "form" && idx === currentStep){
                            return <FormPreview form={step.form!} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
                        }
                        if(step.action === "textScreen" && idx === currentStep){
                            return <TextPreview textScreen={step.textScreen!} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
                        }
                        if(step.action === "end screen" && idx === currentStep){
                            return <EndPreview end={step.thankYouPage!} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
                        }
                        return null;
                    })}
                    {(currentStep >= steps.length)
                    ?<div className="flex flex-col gap-3">
                        <p className="text-xl">You've reached the end!</p>
                        <p>Click bellow to restart your preview</p>
                        <button onClick={()=>{setCurrentStep(0)}} className="w-fit btn rounded-full bg-brandPurple text-white font-light">Restart Preview</button>
                     </div>
                    :null
                    }
                </div>
                :<div className="skeleton h-96 w-full"></div>
            }
        </div>
    );
};

export default FunnelPreview;