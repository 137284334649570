import React, { useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateData } from "../../Context";

interface Props {
    dateData : DateData[],
    dateTotals : {[key : string] : any},
    magnetLength : number,
}

const SourceCampaignVisuals = ({dateData, dateTotals, magnetLength}:Props) : React.ReactElement => {
    const [filterBy, setFilterBy] = useState<string>("source");

    return(
        <div className="flex flex-col">
            <div className="w-full ">
            
                <div>
                    {
                        (dateData.length > 0)
                        ?<>
                            <ResponsiveContainer height={250} width={"100%"}>
                                <AreaChart width={(window.innerWidth)} height={250} data={dateData}>
                                    <defs>
                                        <linearGradient id="colorView" x1={0} x2={0} y1={0} y2={1}>
                                            <stop offset="5%" stopColor="#6156A5" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#6156A5" stopOpacity={0}/>
                                        </linearGradient>
                                        <linearGradient id="colorCompl" x1={0} x2={0} y1={0} y2={1}>
                                            <stop offset="5%" stopColor="#4CD964" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#4CD964" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                                    <XAxis dataKey="date" hide={true}/>
                                    <YAxis orientation="right"/>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="views" stackId="1" stroke="#6156A5" fill="url(#colorView)" fillOpacity={0.6}/>
                                    <Area type="monotone" dataKey="completions" stackId="3" stroke="#4CD964" fill="url(#colorCompl)" fillOpacity={0.6}/>
                                </AreaChart>
                            </ResponsiveContainer>
                        
                        </>
                        :<>täs</>
                    }
                </div>
                
            </div>
            <div className="mt-5 bg-brandLightGray rounded-lg shadow-md w-[100%]">
                <div className="flex flex-row justify-start items-center p-5 gap-2">
                    <p className="text-xl">Results by</p>
                    <div className="join">
                        <button className={`btn join-item rounded-2xl font-light border-2 border-brandPurple hover:bg-brandLightPurple hover:text-brandLightGray hover:border-brandLightPurple
                        ${(filterBy === "source")?"bg-brandPurple text-brandLightGray":"bg-brandLightGray text-black"}`}
                        onClick={()=>{setFilterBy("source");}}>Source</button>
                        <button className={`btn join-item rounded-2xl font-light border-2 border-brandPurple hover:bg-brandLightPurple hover:text-brandLightGray hover:border-brandLightPurple
                        ${(filterBy === "campaign")?"bg-brandPurple text-brandLightGray":"bg-brandLightGray text-black"}`}
                        onClick={()=>{setFilterBy("campaign");}}>Campagin</button>
                    </div>  
                </div>
                {
                    (dateTotals && dateTotals.sources && dateTotals.campaigns)
                    ?<>
                        <table className="w-full">
                            <thead>
                                <tr className="text-left border-b-[1px]">
                                    <th className="capitalize p-3 pt-0 w-[15%]">{filterBy}</th>
                                    <th className="w-[12%]">Page Views</th>
                                    <th className="w-[12%]">Funnel Starts</th>
                                    <th className="flex-row justify-start items-center gap-1 w-[12%]">
                                        Start Rate
                                        <span className="tooltip tooltip-top font-light" data-tip={"Starts / Page Views"}><FaRegQuestionCircle className="size-4 ml-1"/></span>
                                    </th>
                                    <th className="w-[12%]">Funnel Completions</th>
                                    <th className="flex-row justify-start items-center gap-1 w-[12%]">
                                        Completion Rate
                                        <span className="tooltip tooltip-top font-light" data-tip={"Completions / Starts"}><FaRegQuestionCircle className="size-4 ml-1"/></span>
                                    </th>
                                    <th className="flex-row justify-start items-center gap-1 w-[12%]">
                                        Conversion Rate
                                        <span className="tooltip tooltip-top font-light" data-tip={"Completions / Page Views"}><FaRegQuestionCircle className="size-4 ml-1"/></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dateTotals[`${filterBy}s`].map((filter : any, idx : number) => {
                                        return<tr key={idx} className={`${(idx % 2 === 0)?"bg-white":"bg-brandLightGray"} hover:bg-brandLightPurple hover:text-white`}>
                                                <td className="p-3">{(filter[filterBy] === null)?"(Not set)":filter[filterBy]}</td>
                                                <td>{filter[`step 0`].view}</td>
                                                <td>{filter[`step 0`].start}</td>
                                                <td>
                                                    {((filter[`step 0`]) && filter[`step 0`].start / filter[`step 0`].view && (filter[`step 0`].start / filter[`step 0`].view) !== Infinity)
                                                    ? (filter[`step 0`].start / filter[`step 0`].view * 100).toFixed(2) 
                                                    : 0}
                                                     %
                                                </td>
                                                <td>{(filter[`step ${magnetLength - 1}`]) ? filter[`step ${magnetLength - 1}`].finish : 0}</td>
                                                <td>
                                                    {
                                                        ((filter[`step ${magnetLength - 1}`]) && filter[`step ${magnetLength - 1}`].finish / filter[`step ${0}`].start && (filter[`step ${magnetLength - 1}`].finish / filter[`step ${0}`].start) !== Infinity)
                                                        ?(filter[`step ${magnetLength - 1}`].finish / filter[`step ${0}`].start * 100).toFixed(2)
                                                        :0
                                                    } %
                                                </td>
                                                <td>
                                                    {
                                                        ((filter[`step 0`]) && (filter[`step ${magnetLength - 1}`]) 
                                                        && (filter[`step ${magnetLength - 1}`].finish / filter[`step 0`].view)
                                                        && (filter[`step ${magnetLength - 1}`].finish / filter[`step 0`].view) !== Infinity)
                                                        ?(filter[`step ${magnetLength - 1}`].finish / filter[`step 0`].view * 100).toFixed(2)
                                                        :0
                                                    } %
                                                </td>
                                              </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                    :<>
                    </>
                }
            </div>
        </div>
    );
};

export default SourceCampaignVisuals;