import React, { useContext } from "react";
import { Context } from "../Context";
import { FiLogOut } from "react-icons/fi";

interface Props {
    pageName : string,
    pageDescription? : string
}

const NavBar = ({pageName, pageDescription} : Props) : React.ReactElement => {
    const { user, userLogOut } = useContext(Context);

    return(
        <div className="w-full bg-brandLightGray p-5 flex shadow-sm max-lg:hidden" style={{boxShadow :"1px 0px 4px 0.5px gray"}}>
            <div className="flex-1">
                <div>
                    <h3 className="text-2xl">{pageName}</h3>
                    <p>{(pageDescription)?<span>{pageDescription}</span>:null}</p>
                </div>
            </div>
            <div className="dropdown dropdown-end">
                <div className="tooltip tooltip-left" data-tip="Open user settings">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar placeholder">
                        <div className="w-52 rounded-full bg-brandPurple text-brandLightGray text-xl font-light">
                            <span>{user.first_name[0]}{user.last_name[0]}</span>
                        </div>
                    </div>
                </div>
                <ul tabIndex={0} className="dropdown-content rounded-box z-10 mt-2 p-3 shadow bg-neutral-50 text-black">
                    <li className="p-2 rounded-md flex flex-col text-xs mt-2">
                        <span className="text-sm">{`${user.first_name} ${user.last_name}`}</span>
                        <span className="text-brandPurple">{user.email}</span>
                    </li>
                    <li className="hover:bg-brandLightGray p-2 rounded-md cursor-pointer flex flex-row mt-2" onClick={userLogOut}>
                        <FiLogOut className="size-5 text-black"/>Log out
                    </li>
                </ul>
            </div>
           
        </div>
    );
};

export default NavBar;