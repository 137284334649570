import React, { useContext, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Context } from "../../components/Context";
import { Link } from "react-router-dom";

const SignInPage = () : React.ReactElement => {
    const {login, error, setError} = useContext(Context);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const LoginHandler = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login(email.toLowerCase(), password);
        setLoading(false);
    }

    return(
        <div className="flex lg:flex-row max-lg:flex-col w-screen h-screen bg-brandPurple lg:justify-center lg:items-center">
            <div className="lg:w-1/2 bg-brandLightGray flex items-center justify-center max-lg:p-5 h-full">
                <div className="lg:w-2/3 p-5 h-screen items-start justify-center flex flex-col">
                    <img src="CX-Tailor-Logo-Slogan.png" alt="CX Tailor logo" className="w-1/2 mt-10"/>
                    <h2 className="mt-10 mb-4 text-2xl">Sign in to your account:</h2>
                    <form onSubmit={LoginHandler} onChange={()=>{setError(false); setLoading(false);}} className="text-lg w-full" id="testi">
                        <div className="label">
                            <span className="label-text">Email:</span>
                        </div>
                        <label className="input input-bordered flex items-center gap-2 w-[90%]">
                            <input type="email" name="email" id="email" placeholder="email" className="w-[95%]" value={email} required
                            onChange={(e)=>{setEmail(e.target.value as string)}}
                            />
                            <FaRegCircleUser />
                        </label>
                        <div className="label">
                            <span className="label-text">Password</span>
                        </div>
                        <label className="input input-bordered flex items-center gap-2 w-[90%]">
                            <input type={(showPassword)?"text":"password"} name="password" id="password" placeholder="Password" className="w-[95%]" value={password} required
                            onChange={(e)=>{setPassword(e.target.value as string)}}
                            />
                            {(showPassword)?<AiOutlineEyeInvisible onClick={()=>{setShowPassword(!showPassword)}}/>:<AiOutlineEye onClick={()=>{setShowPassword(!showPassword)}}/>}
                        </label>
                        <button className="btn mt-4 bg-brandPurple text-brandLightGray font-light hover:bg-brandLightPurple" type="submit" onClick={()=>{setLoading(true)}}>
                            {(loading)?<span className="loading loading-spinner loading-md"></span>:null}Sign in
                        </button>
                        {
                            (error)
                            ?<div className="mt-5 w-[90%]">
                                <p className="text-red">Incorrect email or password. Please try again</p>
                            </div>
                            :null
                        }
                    </form>
                    <p className="mt-5">Don't have an account? <Link to="/register" className="text-brandPurple underline">Create a free account here</Link></p>
                </div>
            </div>
            <div className="lg:w-1/2 bg-brandPurple flex justify-center items-center flex-col max-lg:p-5">
                <div className="lg:w-2/3 lg:text-center text-brandLightGray">
                    <h2 className="text-2xl">Start optimizing your customer journeys</h2>
                    <p className="mt-5">
                        Seamlessly capture leads with interactive video/questionnaire funnels. 
                        Elevate your customer journey and maximize your conversions. 
                        Monitor and optimize the performance of your lead magnets through our user friendly dashboards
                    </p>
                </div>
                <img src="CX-Tailor-mockup.jpg" alt="CX Tailor mockup" className="w-2/3"/>
            </div>
        </div>
    );
};

export default SignInPage;