import React, { useContext } from "react";
import NavBar from "../../components/general/NavBar";
import { Context } from "../../components/Context";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import FunnelPreview from "../../components/user/sequenceInfo/FunnelPreview";
import EmbedCode from "../../components/user/sequenceInfo/EmbedCode";

const PreviewAndEmbed = () : React.ReactElement => {
    const {selectedMagnet, apicall} = useContext(Context);

    return(
        <div>
            <NavBar pageName={`Lead Magnets | ${(selectedMagnet && selectedMagnet.form_name)?selectedMagnet.form_name:""}`} 
            pageDescription="Preview & Embed"/>
            <div className="p-5">
                <Link to="/sequences/info" className="flex flex-row justify-start items-center text-lg text-brandPurple">
                    <MdArrowBackIosNew className="size-5 mr-2"/>Back to Funnel info
                </Link>
            </div>
            <div className="pl-5 ml-5">
                <p className="text-2xl">Preview Your Lead Funnel &#128064;</p>
            </div>
            <div className="flex flex-row justify-start items-start p-5">
                <div className="w-1/2">
                    <FunnelPreview steps={(selectedMagnet) ? selectedMagnet.steps : null}/>
                </div>
                <div className="w-1/2">
                    <EmbedCode leadMagnet={(selectedMagnet) ? selectedMagnet : null} sourceURL={apicall}/>
                </div>
            </div>
        </div>
    );
};

export default PreviewAndEmbed;