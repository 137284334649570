import React, { useContext, useState } from "react";
import { Context } from "../../Context";
import { MdBrightness1 } from "react-icons/md";
import SelectTestWinner from "./SelectTestWinner";

const ABtestResults = () : React.ReactElement => {
    const {selectedABtest, selectedABdata} = useContext(Context);
    const [goal, setGoal] = useState<string>("mainGoal");

    return(
        <div className="p-5 mt-10 relative">
            <p className={`text-sm flex flex-row items-center animate-pulse gap-1 ${(selectedABtest && selectedABtest.running)?"text-green":"text-red"}`}>
                <MdBrightness1 />{(selectedABtest && selectedABtest.running)?"AB-test running":"AB-test stopped"}
            </p>
            <div className="flex flex-row">
                <p className="text-xl w-1/2">
                    Results from: 
                    <span className="text-brandPurple">{` ${(selectedABtest)?selectedABtest.test_name:null}`}</span>
                </p>
                <p className="w-1/2 text-xl text-right pr-10">
                    {
                        (selectedABtest)
                        ?<span>
                            {new Date(selectedABtest.startDate).toLocaleDateString("fi-fi")} {" - "} 
                            {(selectedABtest.running) ? new Date().toLocaleDateString("fi-fi") : new Date(selectedABtest.endDate).toLocaleDateString("fi-fi")}
                        </span>
                        :null
                    }
                </p>
            </div>
            <div className="mt-5 bg-brandLightGray rounded-lg shadow-sm">
                <div className="flex flex-row justify-start items-center p-5 gap-2">
                    <div className="join">
                        <button className={`btn join-item rounded-2xl font-light border-1 border-brandPurple hover:bg-brandLightPurple hover:text-brandLightGray hover:border-brandLightPurple
                        ${(goal === "overall")?"bg-brandPurple text-brandLightGray":"bg-brandLightGray text-black"}`}
                        onClick={()=>{setGoal("overall");}}>Overall results</button>
                        <button className={`btn join-item rounded-2xl font-light border-1 border-brandPurple hover:bg-brandLightPurple hover:text-brandLightGray hover:border-brandLightPurple
                        ${(goal === "mainGoal")?"bg-brandPurple text-brandLightGray":"bg-brandLightGray text-black"}`}
                        onClick={()=>{setGoal("mainGoal");}}>Main Goal {(selectedABtest)?<span>- {selectedABdata.mainGoal.name}</span>:""}</button>
                        <button className={`btn join-item rounded-2xl font-light border-1 border-brandPurple hover:bg-brandLightPurple hover:text-brandLightGray hover:border-brandLightPurple
                        ${(goal === "secondaryGoal")?"bg-brandPurple text-brandLightGray":"bg-brandLightGray text-black"}`}
                        onClick={()=>{setGoal("secondaryGoal");}}>Secondary Goal {(selectedABtest)?<span>- {selectedABdata.secondaryGoal.name}</span>:""}</button>
                    </div>  
                </div>
                {
                    (goal !== "overall")
                    ?<table className="w-full">
                    <thead>
                        <tr className="text-left border-b-2">
                            <th className="p-3 pl-5 w-1/4">Variant</th>
                            <th className="">{(selectedABdata)?selectedABdata[goal].Astart.name:""}</th>
                            <th className="">{(selectedABdata)?selectedABdata[goal].Afinish.name:""}</th>
                            <th className="">Expected converison rate</th>
                            <th className="">Improvement</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (selectedABdata && selectedABtest)
                            ?<>
                                <tr className="bg-white hover:bg-brandLightPurple hover:text-brandLightGray">
                                    <td className="p-3">Original</td>
                                    <td>{selectedABdata[goal].Astart.num}</td>
                                    <td>{selectedABdata[goal].Afinish.num}</td>
                                    <td>
                                    {selectedABdata[goal].resultA} %
                                    </td>
                                    <td></td>
                                    <td className="text-right pr-5 p-3">
                                        <SelectTestWinner variant="A" steps={selectedABtest.Asteps} totalPageviews={selectedABdata.AvariantSteps[0].view + selectedABdata.BvariantSteps[0].view}/>
                                    </td>
                                </tr>
                                <tr className=" hover:bg-brandLightPurple hover:text-brandLightGray">
                                    <td className="p-3">B-variant</td>
                                    <td>{selectedABdata[goal].Bstart.num}</td>
                                    <td>{selectedABdata[goal].Bfinish.num}</td>
                                    <td>
                                        {selectedABdata[goal].resultB} %
                                    </td>
                                    <td className={`${(((selectedABdata[goal].resultB - selectedABdata[goal].resultA) / selectedABdata[goal].resultA * 100) > 0)?"text-green":"text-red"}`}>
                                        {(((selectedABdata[goal].resultB - selectedABdata[goal].resultA) / selectedABdata[goal].resultA * 100) > 0)?"+":""}
                                        {((selectedABdata[goal].resultB - selectedABdata[goal].resultA) / selectedABdata[goal].resultA * 100).toFixed(2)} %
                                    </td>
                                    <td className="text-right pr-5 p-3">
                                        <SelectTestWinner variant="B" steps={selectedABtest.Bsteps} totalPageviews={selectedABdata.AvariantSteps[0].view + selectedABdata.BvariantSteps[0].view}/>
                                    </td>
                                </tr>
                            </>
                            :<p>Loading...</p>
                        }
                    </tbody>
                </table>
                :<table className="w-full">
                <thead>
                    <tr className="text-left border-b-2">
                        <th className="p-3 pl-5 w-1/4">Variant</th>
                        <th className="">Page views</th>
                        <th className="">Funnel starts</th>
                        <th className="">Start rate</th>
                        <th>Funnel Completions</th>
                        <th>Completion Rate</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (selectedABdata && selectedABtest)
                        ?<>
                            <tr className="bg-white hover:bg-brandLightPurple hover:text-brandLightGray">
                                <td className="p-3">Original</td>
                                <td>{selectedABdata.AvariantSteps[0].view}</td>
                                <td>{selectedABdata.AvariantSteps[0].start}</td>
                                <td>{(selectedABdata.AvariantSteps[0].start / selectedABdata.AvariantSteps[0].view * 100).toFixed(2)} %</td>
                                <td>{selectedABdata.AvariantSteps[selectedABdata.AvariantSteps.length - 1].finish}</td>
                                <td>{(selectedABdata.AvariantSteps[selectedABdata.AvariantSteps.length - 1].finish / selectedABdata.AvariantSteps[0].start * 100).toFixed(2)} %</td>
                                <td className="text-right pr-5 p-3">
                                    <SelectTestWinner variant="A" steps={selectedABtest.Asteps} totalPageviews={selectedABdata.AvariantSteps[0].view + selectedABdata.BvariantSteps[0].view}/>
                                </td>
                            </tr>
                            <tr className=" hover:bg-brandLightPurple hover:text-brandLightGray">
                                <td className="p-3">B-variant</td>
                                <td>{selectedABdata.BvariantSteps[0].view}</td>
                                <td>{selectedABdata.BvariantSteps[0].start}</td>
                                <td>{(selectedABdata.BvariantSteps[0].start / selectedABdata.BvariantSteps[0].view * 100).toFixed(2)} %</td>
                                <td>{selectedABdata.BvariantSteps[selectedABdata.BvariantSteps.length - 1].finish}</td>
                                <td>{(selectedABdata.BvariantSteps[selectedABdata.BvariantSteps.length - 1].finish / selectedABdata.BvariantSteps[0].start * 100).toFixed(2)} %</td>
                                <td className="text-right pr-5 p-3">
                                    <SelectTestWinner variant="B" steps={selectedABtest.Bsteps} totalPageviews={selectedABdata.AvariantSteps[0].view + selectedABdata.BvariantSteps[0].view}/>
                                </td>
                            </tr>
                        </>
                        :<p>Loading...</p>
                    }
                </tbody>
            </table>
                }
            </div>
        </div>
    );
};

export default ABtestResults;