import React from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

interface Props {
    title : string,
    result : number,
    percentage : boolean,
    comparison : number,
    bg : string,
    tooltipText : string,
}

const StepV1 = ({title, result, percentage, comparison, bg, tooltipText}:Props) : React.ReactElement => {

    return(
        <div className={`rounded-xl shadow-md ${bg} lg:w-[13%] max-lg:w-full`}>
            <div className="bg-brandLightGray mt-1 rounded-xl p-3 relative h-full">
                <p className="lg:text-xs">{title}</p>
                <p className="xl:text-md 2xl:text-xl lg:text-xs mt-2">
                    {(percentage)?((result)?result.toFixed(2):0)+" %":result}
                    <span className={`2xl:text-sm text-xs p-1 rounded-xl ml-1 ${(result > comparison)?"bg-green":(result === comparison)?"bg-yellow":"bg-red"}`}>
                        {(((result - comparison) / comparison) && ((result - comparison) / comparison) !== Infinity) ? ((result - comparison) / comparison * 100).toFixed(0) : 0} %
                    </span>
                </p>
                <div className="tooltip tooltip-top absolute top-2 right-2" data-tip={tooltipText}>
                    <FaRegQuestionCircle className="size-4 text-brandPurple"/>
                </div>
            </div>
        </div>
    );
};

export default StepV1;