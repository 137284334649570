import React, { useState } from "react";
import { FormInterface, Step } from "../../Context";
import { MdFormatListBulleted } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import FormContent from "./FormContent";
import FormStyle from "./FormStyle";

interface ChangeList {
    stepIdx : number,
    action : string
}

interface Props {
    steps : Step[],
    setSteps : Function,
    step : Step,
    idx : number
    editOnly : boolean,
    changesMadeTo? : ChangeList[],
    setChangesMadeTo? : Function
}

const FormStep = ({steps, setSteps, step, idx, editOnly, changesMadeTo, setChangesMadeTo}:Props) : React.ReactElement => {
    const [editForm, setEditForm] = useState<boolean>(false);
    const [form, setForm] = useState<FormInterface>({
        header : (step.form) ? step.form.header : "",
        description : (step.form) ? step.form.description : "",
        inputFields : (step.form && step.form.inputFields) ? step.form.inputFields : undefined,
        firstNameLabel : (step.form) ? step.form.firstNameLabel : "",
        lastNameLabel : (step.form) ? step.form.lastNameLabel : "",
        companyLabel : (step.form) ? step.form.companyLabel : "",
        emailLabel : (step.form) ? step.form.emailLabel : "",
        emailResults : (step.form) ? step.form.emailResults : "",
        ctaText : (step.form) ? step.form.ctaText : "",
        textScreenStyle : (step.form && step.form.textScreenStyle) ? step.form.textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : (step.form && step.form.h1Style) ? step.form.h1Style : {fontSize : "24", color : ""},
        pStyle : (step.form && step.form.pStyle) ? step.form.pStyle : {fontSize : "18", color : ""},
        labelStyle : (step.form && step.form.labelStyle) ? step.form.labelStyle : {fontSize : "16", color : ""},
        inputStyle : (step.form && step.form.inputStyle) ? step.form.inputStyle : {with : "50", padding : "0", fontSize :"16", borderRadius : ""},
        ctaStyle : (step.form && step.form.ctaStyle) ? step.form.ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "#000000", padding : "10", borderRadius : "10"}
    });
    const [editScreen, setEditScreen] = useState<string>("content");
    const [editContent, setEditContent] = useState<React.ReactElement>(<FormContent idx={idx} form={form} setForm={setForm}/>);

    const saveToStorage = () => {
        sessionStorage.removeItem(`form${idx}`);
        sessionStorage.setItem(`steps`, JSON.stringify(steps));
    }


    const removeStep = () => {
        sessionStorage.removeItem(`form${idx}`);
        sessionStorage.setItem(`steps`, JSON.stringify(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx])));
        setSteps(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx]));
    }

    return(
        <div className="bg-brandLightGray p-3 2xl:w-1/3 w-1/2 lg:w-1/2 rounded-xl shadow-md" key={idx}>
            <div className="flex flex-row justify-start items-center">
                <div className="w-1/2">
                    <p className="flex flex-row justify-start items-center text-lg"><MdFormatListBulleted className="size-6 mr-1 text-brandPurple"/><b>Step {idx + 1}:</b> Form</p>
                </div>
                <div className="w-1/2 flex justify-end gap-2">
                    {(editOnly)
                        ?null
                        :<button className="btn rounded-full border-red boder-[1px] text-black bg-white hover:bg-red hover:text-white font-light"
                        onClick={removeStep}>
                            <MdDeleteForever className="size-4"/>Remove
                        </button>
                    }
                    <button onClick={()=>{setEditForm(true);}} 
                    className="btn rounded-full font-light border-[1px] text-sm border-brandPurple bg-white hover:bg-brandLightPurple hover:text-white">
                        <FaPen className="size-4"/> Edit Step
                    </button>
                </div>
            </div>
            <div className="p-2">
                <div className={`text-${form.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${form.textScreenStyle.padding}px`, 
                        backgroundColor : `${form.textScreenStyle.backgroundColor}`}}>
                        {(form.header)?
                        <p style={{fontSize : `${form.h1Style.fontSize}px`, color : `${form.h1Style.color}`}}>
                            {form.header}
                        </p>
                        :null}
                        {(form.description)?
                        <p style={{fontSize : `${form.pStyle.fontSize}px`, color : `${form.pStyle.color}`}}>
                            {form.description}
                        </p>
                        :null}
                        <form>
                            {
                            (form.inputFields && form.inputFields.length > 0)
                            ?<>{form.inputFields.map((input : any, idx : number) => {
                                return<div key={idx}>
                                        <label htmlFor={input.name}  style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                            {input.label}
                                        </label><br/>
                                        <input readOnly type={input.type} name={input.type} className="border-[1px]"
                                        style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                                        fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/>
                                    </div>
                            })}</>
                            :<>
                            <label htmlFor="firstName" style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                            {(form.firstNameLabel)?form.firstNameLabel:"Fist name"}
                            </label><br/>
                            <input type="text" name="firstName" className="border-[1px]" 
                            style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                            fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/><br/>
                            <label htmlFor="lastName" style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                {(form.lastNameLabel)?form.lastNameLabel:"Last name"}
                            </label><br/>
                            <input type="text" name="lastName" className="border-[1px]"
                            style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                            fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/><br/>
                            <label htmlFor="email" style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                {(form.emailLabel)?form.emailLabel:"Email"}
                            </label><br/>
                            <input type="text" name="email" className="border-[1px]"
                            style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                            fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/><br/>
                            <label htmlFor="company" style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                {(form.companyLabel)?form.companyLabel:"Company"}
                                </label><br/>
                            <input type="text" name="company" className="border-[1px]"
                            style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                            fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/><br/>
                            </>
                            }
                            <button className="mt-2"
                            style={{fontSize : `${form.ctaStyle.fontSize}px`, padding :  `${form.ctaStyle.padding}px`, borderRadius : `${form.ctaStyle.borderRadius}px`,
                            color :  `${form.ctaStyle.color}`, backgroundColor :  `${form.ctaStyle.backgroundColor}`}}>
                                {(form.ctaText)?form.ctaText:"Submit form"}
                            </button>
                        </form>
                    </div>
            </div>
            <input type="checkbox" checked={editForm} id="videoEditor" className="modal-toggle" readOnly/>
            <div className="modal" role="dialog">
                <div className="modal-box w-[80%] max-w-none">
                    <div className="flex flex-row justify-start items-center pb-5 border-b-[1px]">
                        <div className="w-1/3">
                            <p className="text-lg"><b>Editing:</b> Step {idx + 1}: Form</p>
                        </div>
                        <div className="w-1/3 join flex justify-center">
                            <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                            ${(editScreen === "content")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                            onClick={()=>{; setEditScreen("content"); setEditContent(<FormContent idx={idx} form={form} setForm={setForm}/>)}}>
                                Content
                            </button>
                            <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                            ${(editScreen === "style")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                            onClick={()=>{setEditScreen("style"); setEditContent(<FormStyle idx={idx} form={form} setForm={setForm}/>)}}>Style</button>
                        </div>
                        <div className="w-1/3 flex justify-end gap-2">
                            <button className="btn rounded-full border-2 border-red text-red font-light hover:bg-red hover:text-white bg-white"
                            onClick={()=>{setEditForm(false);}}>Cancel</button>
                            <button className="btn bg-brandPurple text-white rounded-full font-light hover:bg-brandLightPurple" 
                            onClick={()=>{setEditForm(false); step.form = form; saveToStorage();
                            if(setChangesMadeTo && changesMadeTo){setChangesMadeTo([...changesMadeTo, {stepIdx : idx, action : "form"}])}; }}>Save & Close</button>
                        </div>
                    </div>
                    {editContent}
                </div>
            </div>
        </div>
    );
};

export default FormStep;