import React from "react";

interface Props {
    loadign : boolean,
    color : string,
    textColor: string,
    title : string,
    result : number,
    percentage : boolean,
    comparison : number,
    comparisonText? : string,
}

const StepResultCard = ({color, textColor, title, result, percentage, comparison, comparisonText}:Props) : React.ReactElement => {
    const comparisonResult : number = (((result - comparison) / comparison) && (result - comparison) / comparison !== Infinity)?(result-comparison)/comparison * 100:0
    return(
        <div className={`${color} p-2 pr-4 pl-4 rounded-lg ${textColor} lg:w-1/4 max-lg:w-full flex flex-col justify-center items-start`}>
            <p>{title}</p>
            <p>{(percentage)?`${result.toFixed(0)} %`:result}
                {
                    (comparisonText)
                    ?<span className="text-[0.7rem] bg-brandLightGray text-black ml-1 p-1 pr-2 pl-2 rounded-xl">{comparison} {comparisonText}</span>
                    :<span className="text-xs bg-brandLightGray text-black ml-1 p-1 pr-2 pl-2 rounded-xl">{(result > comparison)?"+":null}{comparisonResult.toFixed(0)} %</span>
                }
            </p>
        </div>
    );
};

export default StepResultCard;