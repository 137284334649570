import React from "react";
import { Workspace } from "../../Context";

interface Props {
    workspace : Workspace
}

const AccountInfo = ({workspace}:Props) : React.ReactElement => {
    return(
        <div className="m-5 rounded-lg shadow-md">
            <div className="p-5 rounded-lg bg-brandLightGray">
                <p className="font-semibold">Account Info</p>
            </div>
            <div className="p-5">
                <div>
                    <p>Account name</p>
                    {
                        (workspace)
                        ?<input className="input input-bordered w-[90%]" disabled value={workspace.workspace_name}/>
                        :<span className="skeleton h-10 w-[90%]"></span>
                    }
                </div>
                <div className="mt-5">
                    <p>Account level</p>
                    {
                        (workspace)
                        ?<p className="font-semibold">{workspace.level}</p>
                        :<span className="skeleton h-10 w-[90%]"></span>
                    }
                </div>
            </div>
        </div>
    );
};

export default AccountInfo;