import React, { useContext } from "react";
import NavBar from "../../components/general/NavBar";
import { Context } from "../../components/Context";
import AccountInfo from "../../components/user/settingsMain/AccountInfo";
import UserList from "../../components/user/settingsMain/UserList";

const SettingsPage = () : React.ReactElement => {
    const {workspace, workspaceUsers, user, invitedUsers} = useContext(Context);

    return(
        <div>
            <NavBar pageName="Settings"/>
            <AccountInfo workspace={(workspace)?workspace:undefined}/>
            <UserList users={(workspaceUsers)?workspaceUsers:undefined} user={(user)?user:undefined} invitedUsers={(invitedUsers)?invitedUsers:undefined}/>
        </div>
    );
};

export default SettingsPage;