import React, { ReactElement } from "react";

interface Props {
    title : string,
    results : number,
    resultsEnd? : string,
    icon : ReactElement,
    iconColor : string,
    comparisonResult? : number,

}

const StatCard = ({title, results, resultsEnd, icon, iconColor, comparisonResult}: Props) : React.ReactElement => {

    return(
        <div className="stats shadow-md 2xl:w-1/5">
            <div className="stat bg-brandLightGray">
                <div className="stat-figure">
                    <div className="avatar placeholder">
                        <div className={`
                            text-neutral-content rounded-full w-16 
                            ${(comparisonResult)?((results > comparisonResult)?"bg-green":((results === comparisonResult)?"bg-yellow":"bg-red")):iconColor}
                            `}>
                            {icon}
                        </div>
                    </div>
                </div>
                <p className="stat-title text-black">{title}</p>
                <p className="stat-value">{results} {resultsEnd}</p>
            </div>
        </div>
    );
};

export default StatCard;