import React, { useContext, useRef, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Context } from "../../components/Context";
import { Link } from "react-router-dom";

interface Props {
    newUser : any,
    setNewUser : Function,
    setEmailChecked : Function
}

const FirstStep = ({newUser, setNewUser, setEmailChecked}: Props) : React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(false);
    const emailRef = useRef<HTMLInputElement>(null);
    const checkRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string>();

    const updateChanges = () => {
        setError(undefined);
        setNewUser({...newUser, email : emailRef.current?.value, termsAccepted : checkRef.current?.checked});
    }

    const startRegister = async(event : React.FormEvent<HTMLFormElement>) : Promise<void> => {
        event.preventDefault();
        try{
            const connection = await fetch(`/api/register/checkEmail/${emailRef.current?.value.toLowerCase()}`);
            if(connection.status === 200){
                setEmailChecked(true);
            }else if(connection.status === 302){
                setError("Email address already in use");
                setLoading(false);
            }
        }catch(e:any){
            setError("Something went wrong");
        }
    }

    return(
        <div className="xl:w-2/3 lg:p-5 h-screen flex flex-col justify-center items-start">
            <img src="CX-Tailor-Logo-Slogan.png" alt="CX Tailor logo" className="w-1/2"/>
            <h2 className="mt-10 mb-4 text-2xl">Sign up for a free account</h2>
            <p>Forever free. No credit card required.</p>
            <form onChange={updateChanges} onSubmit={startRegister} className="text-lg" id="testi">
                <div className="label">
                    <span className="label-text">Business email:</span>
                </div>
                <label className="input input-bordered flex items-center gap-2 w-[90%]">
                    <input type="email" name="email" id="email" placeholder="Business email" className="w-[95%]" required ref={emailRef}/>
                    <FaRegCircleUser />
                </label>
                <div className="flex flex-row justify-start items-center gap-3 mt-3">
                    <input type="checkbox" checked={newUser.current?.checked} className="checkbox-primary" required ref={checkRef}/><label>I accept CX Tailors' Privacy Policy & Terms</label>
                </div>
                <button className="btn mt-4 bg-brandPurple text-brandLightGray font-light hover:bg-brandLightPurple" type="submit" onClick={()=>{setLoading(true)}}>
                    {(loading)?<span className="loading loading-spinner loading-md"></span>:null}Sign in
                </button>
            </form>
            <p className="mt-5">Already have an account? <Link to="/login" className="text-brandPurple underline">Sign in here</Link></p>
            {
                (error)
                ?<div role="alert" className="alert alert-warning mt-3">
                    <p>{error}</p>
                </div>
                :null
            }
        </div>
    );
}

const LastStep = ({newUser, setNewUser, setEmailChecked}:Props) : React.ReactElement => {
    const {getWorkspace, getWorkspaceUsers, getWorkspaceData, getWorkspaceForms, getWorkspaceLeads, setUserToken, setLoadDone, navigate, setUser, getInvitedUsers} = useContext(Context);
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const companyRef = useRef<HTMLInputElement>(null);
    const fNameRef = useRef<HTMLInputElement>(null);
    const lNameRef = useRef<HTMLInputElement>(null);
    const p1Ref = useRef<HTMLInputElement>(null);
    const p2Ref = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string>();

    const updateChanges = () => {
        setError(undefined);
        setNewUser({...newUser, 
            company : companyRef.current?.value,
            first_name : fNameRef.current?.value,
            last_name : lNameRef.current?.value,
            password : p1Ref.current?.value
        });
    }

    const register = async(event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(p1Ref.current?.value === p2Ref.current?.value){
            const connection = await fetch(`/api/register/newWorkspaceNewUser`,{
                method:"POST",
                headers : {
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                    },
                body : JSON.stringify(newUser)
            });
            if(connection.status === 200){
                const userFound = await connection.json();
                setUser({
                    _id : userFound[0].user_id,
                    workspace_id : userFound[0].workspace_id,
                    first_name : userFound[0].first_name,
                    last_name : userFound[0].last_name,
                    email : userFound[0].email,
                    user_rights : userFound[0].user_rights
                });
                setUserToken(userFound[0].token);
                localStorage.setItem("userString", JSON.stringify(userFound[0]._id));
                getWorkspace(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceForms(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceData(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceLeads(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceUsers(userFound[0].workspace_id, userFound[0].token);
                getInvitedUsers(userFound[0].workspace_id, userFound[0].token);
                setLoadDone(true);
                navigate("/");
                
            }
        }else{
            setError("Given passwords don't match");
        }
    }

    return(
        <div className="xl:w-2/3 p-5 h-screen flex flex-col justify-center items-start">
            <div className="">
                <img src="CX-Tailor-Logo-Slogan.png" alt="CX Tailor logo" className="w-1/2"/>
                <h2 className="mt-10 mb-4 text-2xl">Finish signing up</h2>
                <p>Fill in a few more details to continue to your free account.</p>
            </div>
            <form onChange={updateChanges} onSubmit={register} className="flex flex-col gap-1 mt-5">
                <div className="flex flex-row justify-start items-center gap-3">
                    <div className="w-1/2">
                        <p>Business email</p>
                        <input type="text" value={newUser.email} readOnly className="input input-bordered border-2 w-full"/>
                    </div>
                    <div className="w-1/2">
                        <p>Company</p>
                        <input type="text" placeholder="Company" defaultValue={""} ref={companyRef} required className="input input-bordered border-2 w-full"/>
                    </div>
                </div>
                <div className="flex flex-row justify-start items-center mt-3 gap-3">
                    <div className="w-1/2">
                        <p>First name</p>
                        <input type="text" placeholder="First name" ref={fNameRef} defaultValue={""} required className="input input-bordered border-2 w-full"/>
                    </div>
                    <div className="w-1/2">
                        <p>Last Name</p>
                        <input type="text" placeholder="Last name" ref={lNameRef} defaultValue={""} required className="input input-bordered border-2 w-full"/>
                    </div>
                </div>
                <div className="flex flex-row justify-start items-center mt-3 gap-3">
                    <div className="w-1/2">
                        <p>Password</p>
                        <label className="input input-bordered flex items-center gap-2 w-full">
                            <input type={(showPassword1)?"text":"password"} defaultValue={""} ref={p1Ref} name="password1" id="password1" placeholder="Password" className="w-[95%]" required />
                            {(showPassword1)?<AiOutlineEyeInvisible onClick={()=>{setShowPassword1(!showPassword1)}}/>:<AiOutlineEye onClick={()=>{setShowPassword1(!showPassword1)}}/>}
                        </label>
                    </div>
                    <div className="w-1/2">
                        <p>Repeat Password</p>
                        <label className="input input-bordered flex items-center gap-2 w-full">
                            <input type={(showPassword2)?"text":"password"} defaultValue={""} ref={p2Ref} name="password2" id="password2" placeholder="Password" className="w-[95%]" required />
                            {(showPassword2)?<AiOutlineEyeInvisible onClick={()=>{setShowPassword2(!showPassword2)}}/>:<AiOutlineEye onClick={()=>{setShowPassword2(!showPassword2)}}/>}
                        </label>
                    </div>
                </div>
                <div className="mt-5">
                    <button type="submit" className="btn bg-brandPurple text-white rounded-2xl hover:bg-brandLightPurple font-light">
                        Continue to your free account
                    </button>
                </div>
            </form>
            <p className="mt-5">Already have an account? <Link to="/login" className="text-brandPurple underline">Sign in here</Link></p>
            {
                (error)
                ?<div role="alert" className="alert alert-warning mt-3">
                    <p>{error}</p>
                </div>
                :null
            }
        </div>
    );
}


const RegisterPage = () : React.ReactElement => {
    const [newUser, setNewUser] = useState<any>({
        email : "",
        first_name : "",
        last_name : "",
        password : "",
        company : "",
        termsAccepted : false
    });
    const [emailChecked, setEmailChecked] = useState<boolean>(false);

    return(
        <div className="flex lg:flex-row max-lg:flex-col w-screen h-screen bg-brandPurple">
            <div className="lg:w-1/2 h-screen bg-brandLightGray flex items-center justify-center max-lg:p-5">
                {
                    (emailChecked)
                    ?<LastStep newUser={newUser} setNewUser={setNewUser} setEmailChecked={setEmailChecked}/>
                    :<FirstStep newUser={newUser} setNewUser={setNewUser} setEmailChecked={setEmailChecked}/>
                }
            </div>
            <div className="lg:w-1/2 bg-brandPurple flex justify-center items-center flex-col max-lg:p-5">
                <div className="lg:w-2/3 lg:text-center text-brandLightGray">
                    <h2 className="text-2xl">Start optimizing your customer journeys</h2>
                    <p className="mt-5">
                        Seamlessly capture leads with interactive video/questionnaire funnels. 
                        Elevate your customer journey and maximize your conversions. 
                        Monitor and optimize the performance of your lead magnets through our user friendly dashboards
                    </p>
                </div>
                <img src="CX-Tailor-mockup.jpg" alt="CX Tailor mockup" className="w-2/3"/>
            </div>
        </div>
    );
};

export default RegisterPage;