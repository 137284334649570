import React, { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { LeadMagnet, Workspace } from "../../Context";

interface Props {
    leadMagnet? : LeadMagnet,
    workspace? : Workspace,
    deleteForm? : Function,
    userToken? : string
}

const DeleteFunnel = ({leadMagnet, workspace, deleteForm, userToken}:Props) : React.ReactElement => {
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

    return(
        <div>
            <button onClick={()=>{setDeleteConfirm(true);}} disabled={!leadMagnet}
            className="btn flex flex-row justify-start items-center gap-2 border-red text-red hover:bg-red hover:text-brandLightGray font-light rounded-xl">
                <MdDeleteForever className="size-5 max-md:hidden"/> Delete
            </button>
            <input type="checkbox" className="modal-toggle" checked={deleteConfirm} id="delete-modal"/>
            <div className="modal" role="dialog">
                <div className="modal-box flex flex-col gap-3">
                    <p className="text-2xl text-red">You are about to delete:</p>
                    <p className="text-xl"><span className="text-red">{(leadMagnet)?leadMagnet.form_name+"":" "}</span> -lead magnet </p>
                    <p>Are you sure you want to continue?</p>
                    <div className="flex flex-row gap-3">
                        <button className="btn bg-white border-2 border-brandLightPurple font-light rounded-full hover:bg-brandLightPurple hover:text-white hover:border-brandLightPurple" 
                        onClick={()=>{setDeleteConfirm(false);}}><IoArrowBack className="size-5"/>Cancel</button>
                        <button className="btn bg-white border-2 border-red font-light rounded-full hover:bg-red hover:text-white hover:border-red" 
                        onClick={()=>{setDeleteConfirm(false); if(deleteForm){
                            deleteForm(workspace!._id, leadMagnet!._id, userToken);
                        }}}><MdDeleteForever className="size-5"/> Delete Permanently</button>
                    </div>
                    <p className="text-xs">This action is irreversible and will delete all information & data related to selected lead magnet</p>
                </div>  
            </div>
        </div>
    );
}

export default DeleteFunnel;