import React, { useContext, useRef } from "react";
import { Context } from "../../Context";

interface Props {
    inviteOpen : boolean,
    setInviteOpen : Function,
}

const InvitePopUp = ({inviteOpen, setInviteOpen}:Props) : React.ReactElement => {
    const {user, workspace, userToken, inviteUser, inviteMsg, setInviteMsg} = useContext(Context);
    const emailRef = useRef<HTMLInputElement>(null);
    const rightsRef = useRef<HTMLSelectElement>(null);

    const sendInvite = () => {
        inviteUser(emailRef.current?.value, rightsRef.current?.value, workspace._id, workspace.workspace_name, user.email, userToken);
        setTimeout(()=>{
            setInviteMsg(undefined);
        }, 5000);
    }

    return(
        <div>
            <input checked={inviteOpen} type="checkbox" className="modal-toggle" readOnly />
            <div role="dialog" className="modal">
                <div className="modal-box bg-white p-5 rounded-lg">
                    <div className="flex flex-col gap-3">
                        <p className="text-2xl">Invite new user</p>
                        <p>Send an email invitation to a new user to join your account</p>
                        <div>
                            <p>User email</p>
                            <input type="text" ref={emailRef} name="email" placeholder="example@example.com" className="input input-bordered w-2/3"/>
                        </div>
                        <div>
                            <p>User rights</p>
                            <select className="select select-bordered" defaultValue="user" name="rights" ref={rightsRef} id="rights">
                                <option disabled>--</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                                <option value="read only">Read only</option>
                            </select>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-3 mt-2">
                            <button onClick={()=>{setInviteOpen(false);}}
                            className="btn rounded-2xl font-light border-brandPurple bg-white hover:bg-brandLightPurple hover:text-white">Cancel</button>
                            <button onClick={()=>{setInviteOpen(false); sendInvite();}}
                            className="btn rounded-2xl font-light bg-brandPurple text-white hover:bg-brandLightPurple">Send invite</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                (inviteMsg)
                ?<div className="toast">
                    <div className={`alert ${(inviteMsg.severity === "success")?"alert-success":(inviteMsg.severity === "warning")?"alert-warning":"alert-error"}`}>
                        <p>{inviteMsg.message}</p>
                    </div>
                </div>
                :null
            }
        </div>
    );
};

export default InvitePopUp;