import React, { useContext } from "react";
import { Context, Lead } from "../../Context";

const GeneratedLeads = () : React.ReactElement => {
    const { loadDone, magnetLeads } = useContext(Context);

    return(
        <div className="m-5 bg-brandLightGray rounded-xl shadow-md">
            {
                (loadDone && magnetLeads.length > 0)
                ?<p className="p-5 text-2xl">{magnetLeads.length} Leads generated from this funnel &#127775;</p>
                :<span className="skeleton w-1/3 h-10"></span>
            }
            <table className="w-full rounded-xl">
                <thead>
                    <tr className="text-left border-b-2">
                        <th className="w-1/5 p-2 pl-5">First Name</th>
                        <th className="w-1/5 p-2">Last Name</th>
                        <th className="w-1/5 p-2">Email</th>
                        <th className="w-1/5 p-2">Company</th>
                        <th className="w-1/5 p-2">Became a lead</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (loadDone && magnetLeads.length > 0)
                        ?<>
                            {
                                magnetLeads.map((lead : Lead, idx : number)=>{
                                    return<tr key={idx} className={`${(idx % 2 === 0)?"bg-white":""} hover:bg-brandLightPurple hover:text-brandLightGray`}>
                                        <td className="p-2 pt-4 pb-4 pl-5">{lead.firstName}</td>
                                        <td className="p-2">{lead.lastName}</td>
                                        <td className="p-2">{lead.email}</td>
                                        <td className="p-2">{lead.company}</td>
                                        <td className="p-2">{lead.created_at}</td>
                                    </tr>
                                })
                            }
                        </>
                        :<>
                        </>
                    }
                </tbody>
            </table>
        </div>
    );
};

export default GeneratedLeads;