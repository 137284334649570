import React, { useContext, useState } from "react";
import { Context, Step } from "../../Context";

interface Props {
    variant : string,
    steps : Step[],
    totalPageviews : number
}

const SelectTestWinner = ({variant, steps, totalPageviews} : Props) : React.ReactElement => {
    const {chooseWinner, selectedABtest, userToken} = useContext(Context);
    const [selectWinner, setSelectWinner] = useState<boolean>(false);

    return(
        <div>
            <button className="btn rounded-lg font-light border-brandPurple" onClick={()=>{setSelectWinner(true);}}>Select as winner</button>
            <input type="checkbox" className="modal-toggle" checked={selectWinner} id="winner-modal"/>
            <div className="modal" role="dialog">
                <div className="modal-box flex flex-col gap-3 text-left text-black">
                    <p className="text-2xl">Confirm <span className="text-brandPurple font-bold">{(variant === "A")?"original variant":"B-variant"}</span> as winner?</p>
                    {(totalPageviews < 500)
                    ?<div>
                        <p className="text-red text-lg">CAUTION!</p>
                        <p>Not enough data to create a reliable conclusion.</p>
                        <p>Are you sure you want to continue even with a low amount of data?</p>
                    </div>
                    :<p></p>
                    }
                    <div className="gap-2 flex flex-row">
                        <button className="btn rounded-lg font-light border-red text-red hover:bg-red hover:text-white" 
                        onClick={()=>{setSelectWinner(false);}}>Cancel</button>
                        <button className="btn rounded-lg font-light border-brandPurple text-brandPurple hover:bg-brandPurple hover:text-white"
                        onClick={()=>{chooseWinner(selectedABtest._id, selectedABtest.form_id, userToken, steps, variant); setSelectWinner(false);}}>Continue</button>
                    </div>
                </div>  
            </div>
        </div>
    );
};

export default SelectTestWinner;