import React, { useContext } from "react";
import { Context, User } from "../../Context";
import { IoPersonRemove } from "react-icons/io5";

interface Props {
    user : User | undefined,
    deleteConfirm : boolean,
    setDeleteConfirm : Function,
}

const DeletePopUp = ({user, deleteConfirm, setDeleteConfirm} : Props) : React.ReactElement => {
    const {deleteUser, userToken} = useContext(Context);

    return(
        <div>
            <input className="modal-toggle" checked={deleteConfirm} type="checkbox" readOnly/>
            <div role="dialog" className="modal">
                <div className="modalBox bg-white rounded-xl p-5">
                    <div className="flex flex-col gap-3">
                        <p className="text-2xl text-red">You're about to remove a user from your account</p>
                        <p><b>Username</b> : {user?.first_name} {user?.last_name}</p>
                        <p><b>Email</b> : {user?.email}</p>
                        <p className="text-red">Are you sure you want to continue?</p>
                        <div className="flex flex-row justify-start items-center gap-3">
                            <button onClick={()=>{setDeleteConfirm(false);}}
                            className="btn border-brandPurple bg-white rounded-2xl hover:bg-brandPurple hover:text-white hover:border-brandPurple font-light">
                                Cancel
                            </button>
                            <button onClick={()=>{setDeleteConfirm(false); deleteUser(user?.workspace_id, user?._id, userToken);}}
                            className="btn font-light border-red bg-white rounded-2xl hover:bg-red hover:text-white hover:border-red">
                                <IoPersonRemove className="size-5"/>Remove user
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeletePopUp;