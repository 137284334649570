import React, { useState } from "react";
import { InvitedUser, User } from "../../Context";
import { IoPersonRemove } from "react-icons/io5";
import { IoPersonAddSharp } from "react-icons/io5";
import { GiPlainCircle } from "react-icons/gi";
import DeletePopUp from "./DeletePopUp";
import InvitePopUp from "./InvitePopUp";

interface Props {
    users : User[],
    user : User,
    invitedUsers : InvitedUser[]
}

const UserList = ({users, user, invitedUsers}:Props) : React.ReactElement => {
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [inviteOpen, setInviteOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    return(
        <div className="m-5 rounded-lg shadow-md">
            <div className="bg-brandLightGray p-5 rounded-lg relative">
                <p className="font-semibold">Users</p>
                <button onClick={()=>{setInviteOpen(true);}}
                className="btn absolute right-5 top-2 bg-brandPurple text-white font-light rounded-2xl hover:bg-brandLightPurple">
                    <IoPersonAddSharp className="size-5"/>Invite new user
                </button>
            </div>
            <div className="p-5">
                <table className="w-full mt-2 rounded-lg shadow-md">
                    <thead className="bg-brandLightGray rounded-t-lg">
                        <tr>
                            <th className="text-left p-3">Email</th>
                            <th className="text-left p-3">Name</th>
                            <th className="text-left p-3">Access</th>
                            <th className="text-left p-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        (users && user)
                        ?<>
                        {users.map((mapUser : User, idx : number) => {
                            return<tr key={idx} className={`${(idx < users.length - 1)?"border-b-[1px] border-brandLightGray":""} hover:text-white hover:bg-brandLightPurple`}>
                                    <td className="p-4">{mapUser.email}</td>
                                    <td className="p-3">{mapUser.first_name} {mapUser.last_name}</td>
                                    <td className="p-3">{mapUser.user_rights}</td>
                                    <td className="p-3 text-right">
                                        {
                                            (mapUser.user_rights === "owner")
                                            ?null
                                            :<>
                                            {
                                                (user.user_rights === "admin" || user.user_rights === "owner")
                                                ?<button onClick={()=>{setSelectedUser(mapUser); setDeleteConfirm(true);}} 
                                                className="btn text-red border-red bg-white hover:bg-red hover:text-white hover:border-red rounded-xl font-light">
                                                    <IoPersonRemove className="size-5"/> Remove
                                                </button>
                                                :null
                                            }
                                            </>
                                        }
                                    </td>
                                  </tr>
                        })}
                        </>
                        :<></>
                        }
                        {
                            (invitedUsers)
                            ?<>
                            {invitedUsers.map((invited : InvitedUser, idx : number)=>{
                                return<tr key={idx}>
                                        <td className="p-3">{invited.email}</td>
                                        <td></td>
                                        <td></td>
                                        <td className="flex flex-row justify-end items-center p-3 gap-2 text-red"><GiPlainCircle className="size-3 animate-pulse"/>Invite pending</td>
                                      </tr>
                            })}
                            </>
                            :null
                        }
                    </tbody>
                </table>
                <DeletePopUp user={(selectedUser)?selectedUser:undefined} deleteConfirm={deleteConfirm} setDeleteConfirm={setDeleteConfirm} />
                <InvitePopUp inviteOpen={inviteOpen} setInviteOpen={setInviteOpen}/>
            </div>
        </div>
    );
};

export default UserList;