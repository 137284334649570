import React, { useContext, useState } from "react";
import { Context } from "../../Context";
import StepV1 from "./StepV1";
import SourceCampaignVisuals from "./SourceCampaingVisuals";
import { MdBrightness1 } from "react-icons/md";

const FunnelAnalytics = () : React.ReactElement => {
    const { loadDone, selectedMagnet, magnetData, getMagnetData, userToken, magnetDateTotals, magnetDateData, magnetLeads} = useContext(Context);
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 14)));
    return(
        <div className="m-5 mt-2">
            <h2 className="text-2xl">Keep track of your results &#128640;</h2>
            <p className="text-md mt-2">Monitor your lead funnels in real time and stay up to date on what's working and what needs improving</p>
            {
                (loadDone && magnetData && selectedMagnet && magnetData.steps14Days && magnetData.stepsComparison)
                ?<div className="flex lg:flex-row max-lg:flex-wrap mt-5 gap-5">
                    {
                        (magnetLeads && magnetLeads.length > 0)
                        ?<StepV1 
                        title="Leads Generated"
                        tooltipText="Users who have submitted your lead forms"
                        bg={(magnetLeads.length > 0)?"bg-green":"bg-red"}
                        result={(magnetLeads)?magnetLeads.length:0} 
                        percentage={false} 
                        comparison={0}
                        />
                        :null
                    }
                    <StepV1 
                    title="Page Views"
                    tooltipText="Amount of page loads"
                    bg={(magnetData.steps14Days[0] && magnetData.steps14Days[0].view > 0)?"bg-green":"bg-red"}
                    result={(magnetData.steps14Days[0].view)?magnetData.steps14Days[0].view : 0} 
                    percentage={false} 
                    comparison={magnetData.stepsComparison[0].view}
                    />
                    <StepV1 
                    title="Funnel Starts"
                    tooltipText="Amount of funnel starts"
                    bg={(magnetData.steps14Days[0] && ((magnetData.steps14Days[0].start / magnetData.steps14Days[0].view) * 100) > 20)?"bg-green"
                        :(magnetData.steps14Days[0] && ((magnetData.steps14Days[0].start / magnetData.steps14Days[0].view) * 100) > 10)?"bg-yellow":"bg-red"}
                    result={(magnetData.steps14Days[0].start)?magnetData.steps14Days[0].start:0} 
                    percentage={false} 
                    comparison={(magnetData.stepsComparison[0])?magnetData.stepsComparison[0].start:0}
                    />
                    <StepV1 
                    title="Start Rate"
                    tooltipText="Starts / page views"
                    bg={(magnetData.steps14Days[0] && ((magnetData.steps14Days[0].start / magnetData.steps14Days[0].view) * 100) > 20)?"bg-green"
                        :(magnetData.steps14Days[0] && ((magnetData.steps14Days[0].start / magnetData.steps14Days[0].view) * 100) > 10)?"bg-yellow":"bg-red"}
                    result={(magnetData.steps14Days[0])?(magnetData.steps14Days[0].start / magnetData.steps14Days[0].view) * 100 : 0} 
                    percentage={true} 
                    comparison={(magnetData.stepsComparison[0])?(magnetData.stepsComparison[0].start / magnetData.stepsComparison[0].view) * 100:0}
                    />
                    <StepV1 
                    title="Completions"
                    tooltipText="Amount of funnel completions"
                    bg={(magnetData.steps14Days[magnetData.steps14Days.length - 1] && ((magnetData.steps14Days[magnetData.steps14Days.length - 1].finish / magnetData.steps14Days[0].start) * 100) >= 5)?"bg-green"
                        :(magnetData.steps14Days[magnetData.steps14Days.length - 1] && ((magnetData.steps14Days[magnetData.steps14Days.length - 1].finish / magnetData.steps14Days[0].start) * 100) >= 2)?"bg-yellow":"bg-red"}
                    result={(magnetData.steps14Days[magnetData.steps14Days.length - 1])?(magnetData.steps14Days[magnetData.steps14Days.length - 1].finish):0} 
                    percentage={false} 
                    comparison={(magnetData.stepsComparison[magnetData.stepsComparison.length - 1])?magnetData.stepsComparison[magnetData.stepsComparison.length - 1].finish:0}
                    />
                    <StepV1 
                    title="Completion Rate"
                    tooltipText="Completions / starts"
                    bg={(magnetData.steps14Days[magnetData.steps14Days.length - 1] && ((magnetData.steps14Days[magnetData.steps14Days.length - 1].finish / magnetData.steps14Days[0].start) * 100) >= 5)?"bg-green"
                        :(magnetData.steps14Days[magnetData.steps14Days.length - 1] && ((magnetData.steps14Days[magnetData.steps14Days.length - 1].finish / magnetData.steps14Days[0].start) * 100) >= 2)?"bg-yellow":"bg-red"}
                    result={(magnetData.steps14Days[magnetData.steps14Days.length - 1])?((magnetData.steps14Days[magnetData.steps14Days.length - 1].finish / magnetData.steps14Days[0].start) * 100):0} 
                    percentage={true} 
                    comparison={(magnetData.stepsComparison[magnetData.stepsComparison.length - 1])?((magnetData.stepsComparison[magnetData.stepsComparison.length - 1].finish / magnetData.stepsComparison[0].start) * 100):0}
                    />
                    <StepV1 
                    title="Conversion Rate"
                    tooltipText="Completions / views"
                    bg={(magnetData.conversion14Days && magnetData.conversion14Days >= 5)?"bg-green"
                        :(magnetData.conversion14Days >= 2)?"bg-yellow":"bg-red"}
                    result={(magnetData.conversion14Days)? magnetData.conversion14Days : 0} 
                    percentage={true} 
                    comparison={(magnetData.conversionComparison)? magnetData.conversionComparison : 0}
                    />
                    
                </div>
                :<div>Loading...</div>
            }
            <div className="mt-6">
                <div className="flex flex-row p-2">
                    <div className="w-1/2 flex justify-start items-end gap-4">
                        <p className="flex flex-row gap-1 justify-start items-center text-brandPurple"><MdBrightness1 className="size-3"/>Page Views</p>
                        <p className="flex flex-row gap-1 justify-start items-center text-green"><MdBrightness1 className="size-3"/>Funnel Completions</p>
                    </div>
                    <div className=" flex flex-row gap-2 items-end justify-end w-1/2">
                        <div className="flex flex-col w-auto">
                            <p>Start date:</p>
                            <input type="date" onChange={(e)=>{setStartDate(new Date(e.target.value))}}
                            value={startDate.toISOString().substring(0,10)}  
                            className="input input-bordered border-brandPurple rounded-3xl cursor-text"
                            />
                        </div>
                        <div className="flex flex-col w-auto">
                            <p>End date:</p>
                            <input type="date" value={endDate.toISOString().substring(0,10)} 
                            onChange={(e)=>{setEndDate(new Date(e.target.value))}} 
                            className="input input-bordered border-brandPurple rounded-3xl cursor-text"/>
                        </div>
                        <button className="btn bg-brandPurple text-brandLightGray rounded-3xl font-light hover:bg-brandLightPurple"
                        disabled={!loadDone && !magnetData && !selectedMagnet}
                        onClick={()=>{getMagnetData(selectedMagnet._id, userToken, startDate, endDate)}}>
                            Show data
                        </button>
                    </div>
                </div>
                <SourceCampaignVisuals 
                dateData={(magnetDateData)?magnetDateData:[]} 
                dateTotals={(magnetDateTotals)?magnetDateTotals:{}} 
                magnetLength={(selectedMagnet)?selectedMagnet.steps.length:0} />
            </div>
        </div>
    );
};

export default FunnelAnalytics;