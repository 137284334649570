import React, { useContext } from "react";
import NavBar from "../../components/general/NavBar";
import { Context, Data, Lead } from "../../components/Context";
import { IoMagnetOutline } from "react-icons/io5";
import { IoPeople } from "react-icons/io5";
import { IoStatsChartSharp } from "react-icons/io5";
import StatCard from "../../components/user/homeDashboard/StatCard";

const HomeDashboard = () : React.ReactElement => {
    const { workspace , loadDone, workspaceLeads, workspaceData, leadMagnets } = useContext(Context);
    return(
        <div>
            <NavBar pageName="Dashboard" pageDescription={`Overview of your ${(workspace)?workspace.workspace_name + " -":""}account`}/>
            {
                (loadDone && leadMagnets && workspaceLeads && workspaceData) 
                ?<div className="w-full p-5 flex lg:flex-row max-lg:flex-col gap-5 mt-5">
                    <StatCard title="Lead Magnets" icon={<IoMagnetOutline className="size-10 text-brandLightGray"/>} iconColor="bg-brandPurple"
                    results={(leadMagnets)?Object.keys(leadMagnets).length : 0}/>
                    <StatCard title="Leads generated" icon={<IoPeople className="size-10 text-brandLightGray"/>} iconColor="bg-brandLightPurple" 
                    results={(workspaceLeads)?Object.keys(workspaceLeads).length : 0}/>
                    <StatCard title="Avg. Conversion" icon={<IoStatsChartSharp className="size-10 text-brandLightGray"/>} iconColor="bg-brandLightPurple" resultsEnd="%"
                    results={(workspaceData)?Number((workspaceData.reduce((sum : number, currentValue : any) => sum + currentValue.conversion14Days, 0) / workspaceData.length).toFixed(2)): 0}
                    comparisonResult={(workspaceData)?Number((workspaceData.reduce((sum : number, currentValue : any) => sum + currentValue.conversionComparison, 0) / workspaceData.length).toFixed(2)): 0}
                    />
                </div>
                :<div className="w-full p-5 flex lg:flex-row max-lg:flex-col gap-5 mt-5">
                    <div className="skeleton lg:w-1/5 max-lg:w-full h-32"></div>
                    <div className="skeleton lg:w-1/5 max-lg:w-full h-32"></div>
                    <div className="skeleton lg:w-1/5 max-lg:w-full h-32"></div>
                </div>
            }
            <div className="xl:w-3/4 p-5 pt-1">
                <h2 className="m-5 text-lg">Top performing funnels:</h2>
                <table className="table bg-brandLightGray shadow-md">
                    <thead className="border-b-2">
                        <tr className="text-lg">
                            <th className="w-[10%]"></th>
                            <th className="w-[33%]">Funnel</th>
                            <th>Steps</th>
                            <th>Start rate</th>
                            <th>Conversion rate</th>
                        </tr>
                    </thead>
                    {
                        (loadDone && workspaceData)
                        ?<tbody>
                            {workspaceData.slice(0,5).map((data : Data, idx : number) => {
                                return<tr key={idx} className="hover:bg-brandLightPurple hover:text-brandLightGray cursor-pointer">
                                        <td>{idx + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{data.steps14Days.length}</td>
                                        <td>{(data.steps14Days[0].view)?(data.steps14Days[0].start / data.steps14Days[0].view * 100).toFixed(1):0} %</td>
                                        <td>{(data.conversion14Days)?data.conversion14Days:0} %</td>
                                      </tr>
                            })}
                        </tbody>
                        :<tbody>
                            {[...Array(5)].map((idx: number) => {
                                return<tr>
                                        <td><span className="skeleton w-full h-full"></span></td>
                                        <td><span className="skeleton w-full h-full"></span></td>
                                        <td><span className="skeleton w-full h-full"></span></td>
                                        <td><span className="skeleton w-full h-full"></span></td>
                                      </tr>
                            })}
                        </tbody>
                    }
                </table>
            </div>
            <div className="xl:w-3/4 p-5 pt-1 mb-5">
                <h2 className="m-5 text-lg">Latest leads:</h2>
                {
                    (loadDone && workspaceLeads)
                    ?<table className="table bg-brandLightGray shadow-md">
                        <thead className="border-b-2">
                            <tr className="text-lg">
                                <th className="w-[10%]"></th>
                                <th className="w-[25%]">Name</th>
                                <th className="w-[25%]">Email</th>
                                <th className="w-[25%]">Became a lead</th>
                                <th className="w-[15%]">Quality</th>
                            </tr>
                        </thead>
                        {
                            (workspaceLeads.length > 0)
                            ?<tbody>
                            {workspaceLeads.slice(0,5).map((lead : Lead, idx : number) => {
                                return<tr key={idx} className="hover:bg-brandLightPurple hover:text-brandLightGray cursor-pointer">
                                        <td>{idx + 1}</td>
                                        <td>{lead.firstName} {lead.lastName}</td>
                                        <td>{lead.email}</td>
                                        <td>{lead.created_at}</td>
                                        <td>{lead.quality}</td>
                                    </tr>
                            })}
                        </tbody>
                            :<tbody>
                                <p>No</p>
                            </tbody>
                        }
                    </table>
                    :<table>
                        <thead className="border-b-2">
                            <tr className="text-lg">
                                <th className="w-[10%]"></th>
                                <th className="w-[33%]">Funnel</th>
                                <th>Steps</th>
                                <th>Start rate</th>
                                <th>Conversion rate</th>
                            </tr>
                        </thead>
                        <tbody>
                                {[...Array(5)].map((idx: number) => {
                                    return<tr>
                                            <td><span className="skeleton w-full h-full"></span></td>
                                            <td><span className="skeleton w-full h-full"></span></td>
                                            <td><span className="skeleton w-full h-full"></span></td>
                                            <td><span className="skeleton w-full h-full"></span></td>
                                        </tr>
                                })}
                            </tbody>
                    </table>
                }
            </div>
        </div>
    );
};

export default HomeDashboard;