import React, { useContext } from "react";
import { MdBrightness1 } from "react-icons/md";
import { Context } from "../../Context";

interface Props {
    idx : number,
    active : boolean,
    thumbnail : string,
    name : string,
    id : string,
    startRate : number,
    startComparison : number,
    conversion : number,
    conversionComparison : number,
    leads? : number,
    leadsComparison? : number,
}

const MagnetCard = ({idx, active, thumbnail, name, id, startRate, startComparison, conversion, conversionComparison, leads, leadsComparison}:Props) : React.ReactElement => {
    const { getSelectedForm, navigate, userToken, getMagnetLeads, getMagnetData, resultsStart, resultsEnd } = useContext(Context);

    return(
        <div className="2xl:w-[22%] xl:w-[30€] lg:w-[30%] h-fit shadow-md rounded-lg bg-brandLightGray p-2 pb-2 relative" key={idx}>
            <div className="absolute top-3 right-4 z-[1]">
                {
                (active)
                ?<div className="tooltip tooltip-left" data-tip="Active"><MdBrightness1 className="size-5 text-green animate-pulse"/></div>
                :<div className="tooltip tooltip-left" data-tip="Inactive - No data from past 14 days"><MdBrightness1 className="size-5 text-red animate-pulse"/></div>
                }
            </div>
            <img src={thumbnail} alt={name} className="h-[200px] object-cover w-full rounded-2xl bg-brandPurple"/>
            <div className="mt-2 relative">
                <h2 className="pt-1 text-sm">{(name)?name.substring(0,25):""}{(name && name.length > 25)?"...":""}</h2>
                <button className="rounded-3xl text-sm pt-2 pb-2 p-3 bg-brandPurple text-brandLightGray font-light absolute top-0 right-2 hover:bg-brandLightPurple"
                onClick={()=>{getSelectedForm(id, userToken); getMagnetLeads(id, userToken); getMagnetData(id, userToken, resultsStart, resultsEnd); navigate("/sequences/info")}}>
                    Open
                </button>
            </div>
            <div className="flex flex-row mt-5 gap-2 w-full">
                <div className={`text-sm p-2 rounded-lg w-1/3 ${((startRate * 100) >= 20)?"bg-green":((startRate * 100) >= 10)?"bg-yellow":"bg-red"}`}>
                    <p className="max-xl:text-xs xl:text-[0.6rem] 2xl:text-xs">Start rate</p>
                    <p className="max-xl:text-xs xl:text-[0.6rem] 2xl:text-xs mt-1">
                        {(startRate *100).toFixed(1)} %
                        <span className="bg-brandLightGray text-[0.6rem] p-1 ml-1 rounded-xl text-black">
                        {
                            (((startRate - startComparison) / startComparison * 100) !== Infinity && ((startRate - startComparison) / startComparison * 100))
                            ?<>{(((startRate - startComparison)/startComparison * 100) > 0)?"+":""}{((startRate - startComparison)/startComparison * 100).toFixed(0)} %</>
                            :<>0 %</>
                        }     
                        </span>
                    </p>
                </div>
                <div className={`text-sm p-2 rounded-lg w-1/3 ${((conversion) >= 5)?"bg-green":((conversion) >= 2)?"bg-yellow":"bg-red"}`}>
                    <p className="max-xl:text-xs xl:text-[0.6rem] 2xl:text-xs">Conversion rate</p>
                    <p className="max-xl:text-xs xl:text-[0.6rem] 2xl:text-xs mt-1">
                        {(conversion).toFixed(1)} %
                        <span className="bg-brandLightGray text-[0.6rem] p-1 ml-1 rounded-xl text-black">
                        {
                            (((conversion - conversionComparison) / conversionComparison * 100) !== Infinity && ((conversion - conversionComparison) / conversionComparison * 100))
                            ?<>{(((conversion - conversionComparison) / conversionComparison * 100) > 0)?"+":""}{((conversion - conversionComparison) / conversionComparison * 100).toFixed(0)} %</>
                            :<>0 %</>
                        }     
                        </span>
                    </p>
                </div>
                {
                (leads !== undefined && leadsComparison !== undefined)
                ?<div className={`text-sm p-2 rounded-lg w-1/3 ${((startRate * 100) >= 20)?"bg-green":((startRate * 100) >= 10)?"bg-yellow":"bg-red"}`}>
                    <p className="max-xl:text-xs xl:text-[0.6rem] 2xl:text-xs">Leads</p>
                    <p className="max-xl:text-xs xl:text-[0.6rem] 2xl:text-xs mt-1">
                        {(leads)}
                        <span className="bg-brandLightGray text-[0.6rem] p-1 ml-1 rounded-xl text-black">
                        {
                            (((leads - leadsComparison) / leadsComparison * 100) !== Infinity && ((leads - leadsComparison) / leadsComparison * 100))
                            ?<>{(((leads - leadsComparison) / leadsComparison * 100) > 0)?"+":""}{((leads - leadsComparison) / leadsComparison * 100).toFixed(0)} %</>
                            :<>0 %</>
                        }     
                        </span>
                    </p>
                </div>
                :null
                }
            </div>
        </div>
    );
};

export default MagnetCard;